
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































.magazine {
  padding-top: 18rem;

  @include mq(l) {
    padding-top: 26rem;
  }
}
